@charset "UTF-8";
.main-review.col,
.main-makers.col,
.main-article-list.col {
  padding: 0 0 0px 0px;
  /*was p:0 0 10 0   */ }

#review-body h3,
#review-body h2 {
  font-size: 23px;
  font-weight: 400;
  font-family: "Arimo", 'Google-Oswald', sans-serif;
  color: #777;
  /*color: #999;*/
  padding-bottom: 1em;
  padding-top: 1em;
  padding-left: 30px;
  padding-right: 30px; }

#review-body h2 {
  color: #333; }

.main-review p {
  margin: 0 30px 15px 30px; }

.main-review p > object embed,
.main-review p > iframe {
  width: 727px;
  height: 409px;
  margin-left: -30px; }

.main-review p > img[width="580"],
.main-review p > img[width="590"] {
  margin: 0;
  width: 727px;
  width: calc(100% + 60px);
  height: auto;
  margin-bottom: 10px;
  margin-left: -30px;
  border: none; }

#review-body h6 {
  padding-bottom: 1em; }

#review-body a {
  text-decoration: underline; }
  #review-body a.emphasis {
    color: var(--color-button-dark-text, white);
    background-color: var(--color-button-dark-background, #757575);
    padding: 0.15em 0.2em;
    text-decoration: none; }
    #review-body a.emphasis:hover {
      background: var(--color-button-dark-hover-background, #d50000); }

#review-body ul {
  margin: 0 60px;
  padding-bottom: 1.4em;
  list-style: disc;
  list-style-position: outside; }

.main-review p + img,
.inline-image {
  margin: 0 auto;
  width: auto;
  max-width: 728px;
  margin-bottom: 10px;
  height: auto;
  display: block;
  image-rendering: optimizeQuality;
  image-rendering: -webkit-optimize-contrast; }

p .inline-image {
  margin: 0 -30px 14px -30px;
  border: none; }
  p .inline-image + font {
    margin: -5px -30px;
    width: calc(100% + 60px); }

#review-body strong font,
img + font {
  color: #888888;
  font: bold 13px "Arimo", Arial, sans-serif; }

#review-body font strong,
img + font strong {
  color: #888888;
  font: bold 13px "Arimo", Arial, sans-serif;
  top: 0 !important; }

img + font {
  display: inline-block;
  background: rgba(230, 230, 230, 0.5);
  padding: 10px 25px;
  width: 100%;
  margin: 0;
  top: -10px;
  position: relative; }

#review-body h6 {
  color: #888888;
  font: bold 11px Arial; }

/*blockquote*/
blockquote {
  padding: 20px 30px 6px 100px;
  margin-bottom: 20px;
  font: 300 26px/1.35 'Google-Oswald', 'Arimo', Arial, sans-serif;
  quotes: "“" "”" "‘" "’";
  color: #777;
  background: rgba(230, 230, 230, 0.2);
  width: 100%;
  position: relative;
  overflow: hidden; }

blockquote::before {
  content: "\201C";
  font-size: 288px;
  line-height: 0.1em;
  margin-right: 0.15em;
  vertical-align: -0.5em;
  top: 116px;
  left: 20px;
  position: absolute;
  opacity: .5;
  font-family: "Google-Oswald";
  color: #DFDFDF; }

/* blockquote p {
    padding-bottom: 20px !important;
} */
blockquote.small p,
blockquote.small,
.small-blockquote p,
.small-blockquote,
.small-blockquote * {
  font: 300 20px/1.45 'Google-Oswald', 'Arimo', Arial, sans-serif; }

/*blockquote::after {
  content:close-quote;
  font-size: 4em;
  vertical-align: -0.4em;
  line-height: 1em;
  margin-right: 0.25em;
  }*/
/* ARTICLE BLURBS */
.article-blurb {
  margin: 0px 0px 15px 0px !important;
  padding: 5px 30px 10px 30px !important;
  width: 100%;
  color: #333;
  background-color: rgba(230, 230, 230, 0.5);
  position: relative;
  border-left-width: 10px;
  border-style: solid; }

.article-blurb-title {
  width: 100%;
  padding: 5px 20px !important;
  margin-bottom: 0;
  border-style: solid;
  border-left-width: 10px; }

.article-blurb li {
  list-style: none !important;
  list-style-position: outside;
  position: relative;
  padding: 3px 0 3px 30px;
  text-indent: -6px; }

.article-blurb li:before {
  content: "\203A";
  position: relative;
  left: -10px;
  opacity: .8;
  font-size: 19px;
  top: 0px;
  font-family: "Arimo"; }

.article-blurb-findings li::before {
  color: #1d819f; }

.article-blurb-features li::before {
  color: rgba(88, 160, 22, 0.5); }

.article-blurb-disadvantages li::before {
  color: #ed9d9d; }

.blurb-title-findings,
.article-blurb-findings {
  border-color: #17819f; }

.blurb-title-features,
.article-blurb-features {
  border-color: rgba(88, 160, 22, 0.5); }

.blurb-title-disadvantages,
.article-blurb-disadvantages {
  border-color: #ed9d9d; }

.g4-plus::before,
.g4-minus::before {
  content: "+" !important;
  color: #14ca00 !important;
  padding-right: 8px;
  font-size: 18px; }

.g4-minus::before {
  content: "\2013" !important;
  color: red !important; }

.two-columns {
  display: grid;
  grid-template-columns: 50% 50%; }

#review-body .article-blurb.two-columns a {
  display: inline;
  text-decoration: none;
  font-weight: bold; }

/* ARTICLE TABLES */
.review-body table {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  margin-bottom: 14px;
  width: 100%; }

.review-body th,
.review-body td {
  background: #f7f7f7;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  font: 13px 'Arimo', Arial, sans-serif;
  padding: 5px 6px;
  vertical-align: top; }

.review-body th {
  font-weight: bold;
  background: #d50000;
  color: white; }

img.pullNeg {
  padding: 0;
  width: 100%;
  margin-bottom: 15px; }

#review-ad table {
  border-bottom: medium none;
  border-top: medium none;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 565px; }

#review-ad th,
#review-ad td {
  background: #FFFFFF;
  border-bottom: medium none;
  border-top: medium none;
  color: #a2a2a2;
  font: 16px Arial;
  padding: 0;
  vertical-align: top; }

.adr {
  margin: 9px 0;
  text-align: center; }

.nr-tags p {
  color: #666666;
  font-size: 11px;
  font-weight: normal;
  padding: 4px 0;
  text-align: left; }

.nr-tags a {
  color: #ABABAB;
  text-decoration: underline; }

.nr-tags a:hover {
  color: #F04F40;
  text-decoration: underline; }

/* --------------------------------
@
@@@@@@  HERO REVIEW IMAGE
@ .review-hd -> holder
@ .review-hd-image -> actual image
@
-----------------------------------*/
/*.review-hd-image {
    position: relative;
    line-height: 0;
    left: 0;
    background: #fff;
    padding: 0 0 0px 0;
    margin: 0 0 0 -31px;
  }
  */
.review-hd-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: url("../i/review-overlay-2.png") repeat-x top left; }

.article-info-line-standalone {
  border-top: 1px solid #ccc;
  background: #ddd;
  overflow: hidden;
  margin-top: 15px; }

.article-info-line .no-blur {
  background-position: 0px 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1; }

.blur.grey:after {
  background-color: rgba(155, 155, 155, 0.2);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 130%;
  height: 130%;
  opacity: 0.7;
  z-index: -1; }

.blur.bottom,
.no-blur.bottom {
  background-position: 0px -269px; }

/*  .article-info-name {
    position: relative;
    font: 400 40px/1.28 "Google-Oswald", Arimo, Arial, sans-serif;
    color: #fff;
    -webkit-box-decoration-break: clone;
    -moz-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
    top: 5px;
    padding: 0 5px;
    display: inline;
    background: url('../i/pattern-diag-dark.gif') repeat;
    text-shadow: 1px 1px 2px rgba(0,0,0,.8);
    }*/
.article-info-name span {
  font: 300 30px Arimo, Arial, sans-serif;
  color: #333;
  background: rgba(255, 255, 255, 0.75);
  padding: 0 5px;
  text-shadow: none;
  position: absolute;
  left: 0;
  top: -35px;
  display: table;
  white-space: nowrap; }

.article-info-name-standalone {
  text-shadow: none;
  font: 400 30px/1.28 "Google-Oswald", Arimo;
  padding: 10px; }

.inverted h1 {
  text-shadow: 1px 1px 2px white;
  color: #000; }

.article-info .article-info-subtitle {
  font: 400 30px "Arimo", Arial, sans-serif;
  display: inline;
  color: #333;
  background: rgba(255, 255, 255, 0.75);
  padding: 0 5px;
  margin-bottom: -12px;
  text-shadow: none; }

.nr-tags select {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 2px; }

.article-info-meta .left {
  float: left;
  padding-left: 10px; }

.article-info-meta-tags {
  font: 12px Arimo; }

.article-info-meta-tags strong {
  text-transform: uppercase; }

.article-info-meta-tags a {
  text-decoration: underline; }

.article-info-meta {
  padding: 0; }

.article-info-meta span {
  font-size: 12px;
  text-align: right;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8); }

.float-left .article-info-meta li {
  float: left; }

.article-info-meta-link a {
  display: block;
  line-height: 44px;
  padding: 12px 15px;
  min-width: 139px;
  font: 400 14px 'Google-Oswald', Arial, sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  text-align: center; }

.article-info .meta-author {
  top: 10px;
  font: 300 16px "Google-Oswald", Arial, sans-serif;
  text-transform: none; }

.meta-author .reviewer {
  margin-left: 5px; }

.meta-author .dtreviewed {
  margin-right: 5px; }

.meta-link-opinions .head-icon {
  font-size: 21px; }

.article-info-name-standalone,
.article-info-meta-tags a:hover {
  color: #d50000; }

#review-body + .review-nav,
.article-pages + .review-nav {
  margin: 15px 0px 0px 0px; }

.article-pages + .review-nav > .review-pages .page-options,
#review-body + .review-nav > .review-pages .page-options {
  bottom: 100%;
  top: inherit; }

.article-pages {
  /*background: rgba(230,230,230,.5);*/
  width: 100%;
  position: relative;
  text-align: right; }

.article-pages strong {
  padding: 10px 7px;
  /*14 11*/
  background: #d50000;
  color: #fff;
  text-transform: uppercase;
  font: 16px/110% "Google-Oswald", Arial, sans-serif;
  vertical-align: middle;
  margin-right: -4px; }

.article-pages:hover strong,
.article-pages:hover span {
  opacity: .85; }

.article-pages .faux-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block; }

.article-pages span {
  font: 700 16px "Arimo", Arial, sans-serif;
  opacity: .85;
  background: #f0f0f0;
  padding: 14px 10px 13px 10px;
  vertical-align: middle; }

.article-pages.youtube-channel-link {
  padding-bottom: 32px; }

#review-body .article-pages.youtube-channel-link a {
  text-decoration: none; }

.pages-next-prev.col {
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0; }

.pages-next-prev a {
  color: #666;
  background: #fff;
  border: 1px solid #bbb;
  min-height: 34px;
  position: relative;
  float: left;
  display: inline-block;
  width: 47%; }

.pages-next-prev .pages-prev {
  margin-right: 5px; }

.pages-next-prev .pages-next:before, .pages-next-prev .pages-prev:before {
  border: 9px solid transparent; }

.pages-next-prev .pages-next:before {
  border-left-color: #999;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  position: absolute;
  z-index: 10;
  top: 7px;
  right: 9px;
  font-size: 14px; }

.pages-next-prev .pages-next:hover, .pages-next-prev .pages-prev:hover {
  border: 1px solid #999; }

.pages-next-prev .pages-prev::before, .review-pages:before {
  -webkit-border-radius: 0;
  z-index: 10;
  content: ""; }

.pages-next-prev .pages-next:hover:before {
  border-left-color: #111; }

.pages-next-prev .pages-next.disabled:hover:before {
  border-left-color: #bbb; }

.pages-next-prev .pages-next.disabled:before {
  border-left-color: #bbb !important; }

.pages-next-prev .pages-prev::before {
  border-right-color: #999;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  position: absolute;
  top: 7px;
  left: 6px;
  font-size: 14px; }

.pages-next-prev .pages-prev:hover::before {
  border-right-color: #111; }

.pages-next-prev .pages-prev.disabled:hover::before {
  border-right-color: #bbb; }

.pages-next-prev .pages-prev.disabled::before {
  border-right-color: #bbb !important; }

.review-nav {
  background: rgba(230, 230, 230, 0.8);
  margin: 0 0 10px;
  width: 100%; }

.review-linx {
  clear: both;
  background: #fff;
  padding: 15px 30px; }

.review-linx li {
  display: inline;
  float: left;
  font: 13px Arimo,Arial,sans-serif;
  margin-right: 2px;
  padding: 0;
  border-right: 1px solid #ccc; }

.review-linx li:last-child {
  border-right: none; }

.review-linx a {
  display: block;
  color: #888;
  padding: 1px 6px; }

.review-linx li:first-child a {
  padding-left: 0; }

.review-pages {
  float: left;
  position: relative;
  border: 1px solid #bbb;
  background: #fff;
  margin-right: 5px; }

.review-pages.col {
  padding: 5px; }

.review-pages .page-selected {
  position: relative;
  padding-left: 5px; }

.review-pages:hover {
  border-color: #999; }

.review-pages:hover:before {
  border-top-color: #111; }

.review-pages:before {
  -moz-border-radius: 0;
  border-radius: 0;
  border: 9px solid transparent;
  border-top-color: #999;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 14px; }

.review-pages .page-options {
  position: absolute;
  left: -1px;
  top: 100%;
  z-index: 9;
  background: #fff;
  display: none;
  border: 1px solid #999;
  border-top: 2px solid #bbb;
  padding: 5px 10px 3px;
  width: 100.4%; }

.page-options:hover, .review-pages:hover .page-options {
  display: block; }

.review-pages a {
  display: block;
  font-size: 15px; }

.review-pages .page-options a {
  padding: 5px 0;
  border-bottom: 1px solid #eee; }

.review-pages .page-options a:hover {
  background: #f9f9f9; }

/* --------------
@
@ Highlighted areas in the dropdown menu
@
-------------- */
.meta-dropdown:first-child {
  margin-top: 10px;
  border-top: 1px solid #000; }

.meta-dropdown:first-child a {
  border-top: 1px solid #000; }

.meta-dropdown:last-child {
  margin-bottom: -2px; }

.meta-dropdown {
  background: #ededed;
  margin-left: -10px;
  padding: 0 10px;
  width: 103.6%; }

.review-pages .meta-dropdown a:hover {
  text-decoration: none;
  background: #f0f0f0; }

/* -------------------------------------
@
@@@@@ IMAGE ROW -> <p>
@ .image-row -> <p> with all the image thumbs and their links
@ it is fixed height with the links having negative margin-top in order to "pop out"
@
--------------------------------------- */
#review-body .image-row {
  padding: 5px 30px 0px 30px;
  margin: 0px;
  width: 99.7%;
  position: relative;
  margin: 0 0 15px 0;
  z-index: 0; }

#review-body .image-row::after,
center + br + font::after,
center ~ font::after {
  border-bottom: 85px solid rgba(230, 230, 230, 0.5);
  width: 100.5%;
  padding: 0 30px;
  margin-left: -30px;
  position: absolute;
  bottom: 0px;
  z-index: -1; }

#review-body .image-row a {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  text-decoration: none; }

#review-body .image-row font {
  position: relative;
  top: -5px; }

#review-body .image-row a img {
  border: 1px solid rgba(230, 230, 230, 0.6);
  box-sizing: content-box; }

#review-body .image-row img,
p img {
  border: 1px solid #d9d9d9;
  box-sizing: content-box; }

#review-body .image-row a:hover img,
p a:hover img {
  webkit-animation-name: linesMoveDown;
  animation-name: linesMoveDown;
  -webkit-animation-duration: .35s;
  animation-duration: .35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 1px solid #d7d7d7; }

a {
  text-decoration: none; }

.center-stage-background {
  position: absolute;
  top: -50%;
  left: -26%;
  width: 128%;
  filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='30'%20/></filter></svg>#blur");
  filter: blur(30px);
  -webkit-filter: blur(30px);
  -moz-filter: blur(30px);
  -ms-filter: blur(30px);
  /* IE lte 9 */
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: -1; }

.main-maker .darken .blur {
  background: #fff; }

.search-results .darken .blur {
  background: none !important; }

.focus-brand-image {
  position: absolute;
  top: 40%;
  left: 43%; }

.overflow {
  overflow: hidden; }

.darken .blur {
  background: rgba(0, 0, 0, 0.15);
  filter: blur(0px) !important;
  transform: translate3d(0, 0, 0); }

/* Article tags and Source/Via styling */
.article-tags {
  clear: both;
  padding: 0px 10px 10px 10px;
  font: normal 12px/1.5 Arimo, Arial, sans-serif;
  text-align: left;
  margin-bottom: 15px;
  color: #777; }

.article-tags p {
  margin: 0; }

.article-tags span {
  font-size: 14px; }

.article-tags a {
  background: #eee;
  padding: 2px 5px;
  text-decoration: none;
  opacity: .85;
  margin-left: 5px; }

.article-tags a:hover {
  background: #d50000;
  color: #fff; }

#review-body p.article-source:last-of-type a[target="_blank"],
#review-body p.article-source:last-of-type {
  font: normal 13px/1.5 "Arimo", Arial, sans-serif; }

#review-body p.article-source:last-of-type a[target="_blank"] + i {
  font: italic 13px/1.5 "Arimo", Arial, sans-serif; }

.review-header a.edit-link,
.article-tags a.edit-link {
  position: initial; }
  .review-header a.edit-link:hover,
  .article-tags a.edit-link:hover {
    color: white; }

.article-tags a.edit-link {
  color: #555; }

.review-header a.edit-link:hover {
  background: #d50000; }

/* Style for Maker pages */
.main-maker .st-text {
  padding: 0 30px; }

.st-text .note {
  padding: 5px 0; }

.main-makers .st-text {
  padding: 30px; }

.main-makers table {
  width: 100%; }

.main-makers table td a {
  display: block;
  font-family: 'Google-Oswald', 'Arimo', Arial;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 35px;
  color: #999;
  padding: 15px 10px 10px 10px; }

.main-makers table td a:hover {
  color: #d50000;
  background: #f9f9f9; }

.main-makers table td a span {
  font-weight: normal;
  font-size: 20px;
  display: block;
  font-family: 'Arimo', Arial, sans-serif;
  margin-top: 10px;
  text-transform: none; }

#body ul.jrGraphControls {
  height: auto !important;
  right: 0px;
  padding: 10px 12px !important;
  top: 0 !important;
  margin: 0 !important; }

#body ul.jrGraph {
  clear: both;
  list-style: none;
  width: 65%;
  overflow: visible !important;
  position: static !important;
  left: auto !important;
  padding-bottom: 0 !important;
  background: none !important;
  float: right !important;
  margin: 0 10px 10px 0 !important; }

#body .jrGraphContainer {
  overflow: hidden; }

#body ul.jrGraph li span.label {
  padding: 7px 0 5px 0; }

.user-avatar {
  max-height: 80px;
  max-width: 125px;
  top: 7px;
  position: relative; }

.makers h3 {
  padding: 0 30px;
  margin-bottom: 10px; }

hr {
  margin-top: 10px;
  display: block; }

#uname.invalid {
  background-color: rgba(255, 0, 0, 0.2); }

#total-hits,
#current-rankings {
  padding: 0 30px; }

#total-hits {
  clear: both;
  display: block;
  font: 12px "Arimo", sans-serif;
  padding: 0 8px 30px 8px;
  text-align: center; }

#total-hits big {
  clear: both;
  color: red;
  display: block;
  font: 38px "Arimo", sans-serif;
  padding: 1px 8px 0;
  text-align: center; }

#current-rankings h2 {
  padding: 15px 0 10px;
  color: #777;
  font-size: 23px;
  font-weight: 400;
  font-family: "Arimo", sans-serif; }

#current-rankings caption {
  padding-top: 20px; }

.main-review.main-news .article-info-line .blur,
.main-review .article-info-line .blur {
  background-image: none !important;
  background-color: Rgba(0, 0, 0, 0.2); }

/* BREADCRUMBS */
.breadcrumbs {
  margin: 15px 0 0 15px;
  text-decoration: none; }

.breadcrumbs .crumb {
  padding: 2px 5px;
  background: #eee;
  color: #555;
  opacity: .85;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none; }

.breadcrumbs .crumb:hover {
  background: #d50000;
  color: #fff; }

.breadcrumbs .arrow {
  position: relative;
  top: 3px;
  font-size: 1.1em; }

.breadcrumbs .arrow:before {
  content: "\e944"; }

/* SHORT REVIEWS CSS including Speedometer animation, conclusion table */
/* SHORT REVIEWS */
.icon-small-menu {
  background: #999;
  border: 1px solid #aaa;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  width: 27px;
  height: 24px;
  display: inline-block;
  text-align: center; }

.review-also-consider {
  border-left: 10px solid rgba(188, 160, 22, 0.5);
  background: #f8f8f8;
  margin-bottom: 20px; }

.review-also-consider-list {
  margin: 15px 30px 0 30px !important;
  overflow: hidden;
  list-style: none !important; }

.review-also-consider-list li:not(:last-child) {
  list-style: none !important;
  display: block;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 15px; }

.also-consider-item {
  width: 75px;
  margin-right: 15px;
  text-align: center;
  color: #888888;
  font: bold 13px "Arimo", Arial, sans-serif; }

.also-consider-item img {
  width: 115%;
  left: -5px;
  position: relative;
  padding: 5px;
  background: #fff;
  z-index: 1;
  opacity: .977;
  height: auto !important; }

.also-consider-item a {
  min-height: 111px;
  padding: 0px 0;
  letter-spacing: -.1px;
  display: block;
  position: relative; }

.also-consider-item a::after {
  content: "";
  position: absolute;
  background-color: #000;
  width: 86px;
  left: -5px;
  top: 0;
  height: 111px;
  z-index: 0; }

.review-also-consider-list h4 {
  margin-top: 0;
  padding-top: 0;
  font: bold 17px Arimo, Arial, sans-seirf;
  color: #777; }

.review-also-consider-list h4 a {
  text-decoration: none !important; }

.review-spotlight-features {
  background: #f8f8f7;
  margin-bottom: 20px;
  border-left: 10px solid rgba(88, 160, 22, 0.5); }

.review-spotlight-features h3,
.review-also-consider h3 {
  background: #fff;
  text-align: left;
  padding: 5px 20px !important; }

.review-spotlight-features ul {
  display: table;
  margin: 0 !important;
  padding: 10px 10px !important;
  width: 100%; }

#review-body {
  font-weight: normal !important; }

.review-spotlight-features li {
  width: 20%;
  height: 100%;
  display: table-cell;
  text-align: center;
  border-left: 1px solid #eaedde;
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: normal; }

.review-spotlight-features li:first-child {
  border-left: none; }

.review-spotlight-features .head-icon {
  font-size: 46px;
  opacity: .4;
  margin-bottom: 10px;
  display: inline-block;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2); }

.review-spotlight-features .accent {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 16px; }

.review-spotlight-features .icon-touch-1 {
  position: relative;
  left: -8px; }

/* SPEEDO NEEDLE PERFORMANCE */
.performance-visual {
  display: table;
  margin: 10px auto;
  width: 65%; }

.speedo:before {
  background: #fbfbfb;
  border-radius: 225px 225px 0 0;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.15) inset;
  content: "";
  height: 121px;
  position: absolute;
  width: 225px; }

.speedo {
  border-radius: 225px 225px 0 0;
  height: 110px;
  margin: 10px auto 0;
  overflow: hidden;
  position: relative;
  width: 225px; }

.speedo:after {
  background: #fff;
  border-radius: 131px 131px 0 0;
  bottom: 0;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.15);
  color: #777;
  content: attr(data-value) "%";
  font-family: Arimo, Arial, sans-serif;
  font-size: 2.9em;
  font-weight: normal;
  height: 83px;
  left: 34px;
  line-height: 100px;
  position: absolute;
  text-align: center;
  width: 157px;
  z-index: 3; }

.speedo span {
  height: 8px;
  left: 10px;
  position: absolute;
  width: 104px;
  bottom: -4px;
  transform-origin: 100% 4px;
  transform: rotate(0deg);
  transition: all 1s; }

.speedo span:after {
  content: "";
  /*background: rgba(179, 50, 36, 1);*/
  background: #999;
  border-radius: 4px;
  box-shadow: 3px -1px 4px rgba(0, 0, 0, 0.4);
  display: block;
  height: 8px;
  transition: all 1s;
  animation: wobble 3s infinite; }

@keyframes wobble {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(0deg); }
  26% {
    transform: rotate(-2deg); }
  32% {
    transform: rotate(0deg); }
  70% {
    transform: rotate(0deg); }
  76% {
    transform: rotate(2deg); }
  82% {
    transform: rotate(0deg); }
  88% {
    transform: rotate(-2deg); }
  94% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(0deg); } }

.wobble {
  animation: wobble 0.3s infinite; }

.bench-vis {
  border-left: 10px solid #f1b05f;
  border-top: none !important;
  border-bottom: none !important;
  width: 100%;
  margin-bottom: 20px; }

.bench-vis-cell {
  position: relative;
  border-top: none !important;
  border-bottom: none !important;
  width: 50%;
  padding: 5px 0 10px 0 !important;
  background: #f8f8f8 !important; }

.bench-vis-name {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 17px;
  color: #777; }

.center {
  text-align: center;
  margin: 0 auto; }

.score-table-exp {
  float: right;
  position: relative;
  text-decoration: none !important;
  left: 5px;
  top: 2px; }

.score-table-exp::before {
  content: "Read more";
  display: none;
  position: absolute;
  bottom: 30px;
  min-width: 210px;
  padding: 10px 5px 9px;
  right: -6px;
  text-align: center;
  background: #333;
  color: #fff;
  font-size: 14px;
  border: 1px solid #bbb; }

.score-table-exp::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #333 transparent transparent transparent;
  content: "";
  position: absolute;
  bottom: 23px;
  right: 5px;
  display: none; }

.score-table-exp:hover::before,
.score-table-exp:hover::after {
  display: block; }

.perc-help {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(127%) translateY(125px);
  font: 700 13px Arimo, Arial, sans-serif;
  padding: 3px 10px;
  color: #333;
  text-decoration: none !important;
  width: 100px;
  text-align: center;
  height: 20px;
  opacity: 0;
  transition: .1s all; }

.perc-help:hover {
  text-decoration: underline !important; }

.bench-vis-cell:hover .speedo::after {
  line-height: 90px; }

.bench-vis-cell:hover .perc-help {
  opacity: 1;
  transform: translateX(130%) translateY(103px); }

.conclusion-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px; }

.review-body .c-table {
  border-bottom: none;
  border-top: none;
  border-collapse: collapse;
  margin-bottom: 0px;
  width: 58%;
  float: right;
  position: relative;
  z-index: 2; }

#review-body .c-table ul {
  margin: 10px 10px; }

.c-table td {
  padding: 0 !important;
  background: none !important; }

.c-table th {
  width: 100%;
  background: #444;
  border: none;
  color: #f8f8f8;
  border-top: 5px solid #f8f8f8;
  font-size: 19px;
  padding-left: 15px; }

.c-table td.section,
.c-table .section-title {
  font-weight: bold;
  text-align: center;
  font-size: 17px;
  border: none !important;
  border-bottom: 1px solid #666 !important;
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff;
  line-height: 200%; }

.c-table td.section-content {
  background: rgba(0, 0, 0, 0.7) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 50%;
  min-width: 50%;
  max-width: 50%; }

.section-content li {
  font: 300 15px/1.35 Arimo !important;
  list-style: none;
  position: relative;
  padding: 9px 0 8px 30px;
  color: #f8f8f8; }

.c-table .section-content li::before {
  content: "";
  border-radius: 100% 100%;
  border: 1px solid #f8f8f8;
  font-weight: bold;
  font-size: 15px;
  min-width: 22px;
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
  margin-right: 8px;
  position: absolute;
  top: 0%;
  left: 0;
  transform: translateY(9px); }

.c-table .content-plus li::before {
  content: "+"; }

.c-table .content-minus li::before {
  content: "\2013";
  line-height: 17px;
  padding-bottom: 3px; }

.c-table .content-minus {
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.c-image {
  width: 100%;
  height: 99.8%;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 100% 52%;
  z-index: 1; }

.c-table .section-title {
  font: bold 23px/1.5 Arimo, Arial, sans-serif;
  text-align: center;
  border-bottom: none !important;
  padding: 5px !important; }

.score-card {
  width: 100%;
  padding: 3px 20px;
  margin: 0 0 45px 0;
  border-bottom: none;
  position: relative;
  display: table;
  background: #f8f8f8; }

.score-card-title {
  margin: 0px 0 0 0;
  padding: 0 10px 0 0;
  color: #E23E57;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  width: 74%;
  display: inline-block;
  vertical-align: middle; }

.score-card-phone {
  color: #f8f8f8 !important;
  margin: 0 !important;
  padding: 0 20px 5px 20px !important;
  font: 300 33px/39px "Google-Oswald", Arimo, Arial, sans-serif !important; }

.score-fill {
  position: relative;
  font-size: 23px; }

.score-card-regular .score-fill {
  width: 150px;
  top: 0px;
  right: 0px;
  line-height: 1em;
  height: 1.1em;
  vertical-align: middle;
  display: inline-block; }

.score-card-regular .score-fill-num {
  float: right;
  position: relative;
  top: 0px;
  font-size: 0.805em; }

.score-card-cell {
  display: table-cell;
  width: 50%;
  vertical-align: middle; }

.score-card-cell:first-child {
  width: 100%; }

.score-card .score-table-exp {
  left: 0px;
  top: 2px; }

.score-amount::after,
.score-amount::before {
  display: block;
  font-size: 0.782608696em;
  line-height: 1.66em;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 0.138888889em;
  letter-spacing: 0.444444444em;
  content: "\2605\2605\2605\2605\2605";
  height: 1em;
  box-sizing: content-box; }

.score-card-total .score-amount::after,
.score-card-total .score-amount::before {
  line-height: 1.53em; }

.score-amount::after {
  color: white;
  z-index: 2;
  overflow: hidden; }

.score-amount::before {
  color: #eaeaea;
  z-index: 1; }

.score-fill::after,
.score-fill::before {
  font-family: "gsmarena", Arial;
  content: "\2B1B\FE0E\2B1B\FE0E\2B1B\FE0E\2B1B\FE0E\2B1B\FE0E";
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0;
  font-size: 1em;
  /* 28 */
  letter-spacing: 0.139130435em;
  height: 1em;
  line-height: 100%; }

.score-card-cell table {
  border: none !important; }

.score-card-cell table td {
  background: none !important;
  border: none !important;
  width: 10%;
  padding: 0 5px 3px 5px !important;
  vertical-align: top;
  color: #f8f8f8;
  font: normal 13px/20px 'Arimo', Arial, sans-serif !important; }

#review-body .score-total-rating {
  width: 25% !important;
  font-weight: normal !important; }

.score-total-section {
  width: 51% !important;
  text-align: right; }

.score-fill::after {
  color: #E23E57;
  overflow: hidden; }

.score-fill::before {
  color: #c3c3c3; }

.score-card-total .final-score {
  text-align: center;
  padding: 15px 0px; }

.final-score .score-fill {
  width: 255px;
  height: 40px;
  margin: 0 auto;
  font-size: 50px; }

.score-all .score-fill {
  font-size: 20px; }

.score-fill[data-score="0.5"]::after {
  width: calc((0.860869565 * 0.5) + (0.139130435em * 0));
  /*width: 0.45em;*/ }

.score-fill[data-score="1"]::after,
.score-fill[data-score="1.0"]::after {
  width: 0.9em; }

.score-fill[data-score="1.5"]::after {
  width: calc((0.860869565em * 1.5) + (0.139130435em * 1)); }

.score-fill[data-score="2"]::after,
.score-fill[data-score="2.0"]::after {
  width: calc((0.851em * 2) + (0.139130435em * 2)); }

.score-fill[data-score="2.5"]::after {
  width: calc((0.860869565em * 2.5) + (0.139130435em * 2)); }

.score-fill[data-score="3"]::after,
.score-fill[data-score="3.0"]::after {
  width: calc((0.851em * 3) + (0.139130435em * 3)); }

.score-fill[data-score="3.5"]::after {
  width: calc((0.860869565em * 3.5) + (0.139130435em * 3)); }

.score-fill[data-score="4"]::after,
.score-fill[data-score="4.0"]::after {
  width: calc((0.851em * 4) + (0.139130435em * 4)); }

.score-fill[data-score="4.5"]::after {
  width: calc((0.860869565em * 4.5) + (0.139130435em * 4)); }

.score-fill[data-score="5"]::after,
.score-fill[data-score="5.0"]::after {
  width: calc((0.860869565em * 5) + (0.139130435em * 4)); }

.score-fill[data-score="0.5"] .score-amount::after {
  width: calc((0.8333em * 0.5) + (0.444444444em * 0));
  /* 0.55em; */ }

.score-fill[data-score="1"] .score-amount::after,
.score-fill[data-score="1.0"] .score-amount::after {
  width: calc((0.8333em * 1) + 0.444444444em * 1);
  /* 1.05em; */ }

.score-fill[data-score="1.5"] .score-amount::after {
  width: calc((0.8333em * 1.5) + 0.444444444em * 1);
  /* 1.74538461em; */ }

.score-fill[data-score="2"] .score-amount::after,
.score-fill[data-score="2.0"] .score-amount::after {
  width: calc((0.8333em * 2) + 0.444444444em * 2);
  /* 2.32em; */ }

.score-fill[data-score="2.5"] .score-amount::after {
  width: calc((0.8333em * 2.5) + 0.444444444em * 2);
  /* 2.88851em; */ }

.score-fill[data-score="3"] .score-amount::after,
.score-fill[data-score="3.0"] .score-amount::after {
  width: calc((0.8333em * 3) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.score-fill[data-score="3.5"] .score-amount::after {
  width: calc((0.8333em * 3.5) + 0.444444444em * 3);
  /* 4.0226923em; */ }

.score-fill[data-score="4"] .score-amount::after,
.score-fill[data-score="4.0"] .score-amount::after {
  width: calc((0.8333em * 4) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.score-fill[data-score="4.5"] .score-amount::after {
  width: calc((0.8333em * 4.5) + 0.444444444em * 4);
  /* 5.26134614em; */ }

.score-fill[data-score="5"] .score-amount::after,
.score-fill[data-score="5.0"] .score-amount::after {
  width: calc((0.8333em * 5) + 0.444444444em * 4);
  /* 5.70384614em; */ }

.final-score .score-fill[data-score="1.1"]::after {
  width: calc((0.8333em * 1.1) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.2"]::after {
  width: calc((0.8333em * 1.2) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.3"]::after {
  width: calc((0.8333em * 1.3) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.4"]::after {
  width: calc((0.8333em * 1.4) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.5"]::after {
  width: calc((0.8333em * 1.5) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.6"]::after {
  width: calc((0.8333em * 1.6) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.7"]::after {
  width: calc((0.8333em * 1.7) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.8"]::after {
  width: calc((0.8333em * 1.8) + 0.139130435em * 1); }

.final-score .score-fill[data-score="1.9"]::after {
  width: calc((0.8333em * 1.85) + 0.139130435em * 1); }

.final-score .score-fill[data-score="2.1"]::after {
  width: calc((0.851em * 2.1) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.2"]::after {
  width: calc((0.851em * 2.3) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.3"]::after {
  width: calc((0.851em * 2.4) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.4"]::after {
  width: calc((0.851em * 2.45) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.5"]::after {
  width: calc((0.860869565em * 2.5) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.6"]::after {
  width: calc((0.851em * 2.6) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.7"]::after {
  width: calc((0.851em * 2.7) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.8"]::after {
  width: calc((0.851em * 2.8) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="2.9"]::after {
  width: calc((0.851em * 2.85) + (0.139130435em * 2)); }

.final-score .score-fill[data-score="3.1"]::after {
  width: calc((0.851em * 3.2) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.2"]::after {
  width: calc((0.851em * 3.3) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.3"]::after {
  width: calc((0.851em * 3.4) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.4"]::after {
  width: calc((0.851em * 3.46) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.5"]::after {
  width: calc((0.860869565em * 3.5) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.6"]::after {
  width: calc((0.851em * 3.6) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.7"]::after {
  width: calc((0.851em * 3.7) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.8"]::after {
  width: calc((0.851em * 3.8) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="3.9"]::after {
  width: calc((0.851em * 3.85) + (0.139130435em * 3)); }

.final-score .score-fill[data-score="4.1"]::after {
  width: calc((0.851em * 4.244) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.2"]::after {
  width: calc((0.851em * 4.355) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.3"]::after {
  width: calc((0.851em * 4.4) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.4"]::after {
  width: calc((0.851em * 4.47) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.5"]::after {
  width: calc((0.860869565em * 4.5) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.6"]::after {
  width: calc((0.851em * 4.6) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.7"]::after {
  width: calc((0.851em * 4.7) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.8"]::after {
  width: calc((0.851em * 4.8) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="4.9"]::after {
  width: calc((0.851em * 4.85) + (0.139130435em * 4)); }

.final-score .score-fill[data-score="1.1"] .score-amount::after {
  width: calc((0.8333em * 1.1) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.2"] .score-amount::after {
  width: calc((0.8333em * 1.2) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.3"] .score-amount::after {
  width: calc((0.8333em * 1.3) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.4"] .score-amount::after {
  width: calc((0.8333em * 1.4) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.5"] .score-amount::after {
  width: calc((0.8333em * 1.5) + 0.444444444em * 1);
  /* 1.74538461em; */ }

.final-score .score-fill[data-score="1.6"] .score-amount::after {
  width: calc((0.8333em * 1.6) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.7"] .score-amount::after {
  width: calc((0.8333em * 1.7) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.8"] .score-amount::after {
  width: calc((0.8333em * 1.8) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="1.9"] .score-amount::after {
  width: calc((0.8333em * 1.85) + 0.444444444em * 1);
  /* 1.05em; */ }

.final-score .score-fill[data-score="2.1"] .score-amount::after {
  width: calc((0.8333em * 2.1) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.2"] .score-amount::after {
  width: calc((0.8333em * 2.2) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.3"] .score-amount::after {
  width: calc((0.8333em * 2.3) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.4"] .score-amount::after {
  width: calc((0.8333em * 2.4) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.5"] .score-amount::after {
  width: calc((0.8333em * 2.5) + 0.444444444em * 2);
  /* 2.88851em; */ }

.final-score .score-fill[data-score="2.6"] .score-amount::after {
  width: calc((0.8333em * 2.6) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.7"] .score-amount::after {
  width: calc((0.8333em * 2.7) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.8"] .score-amount::after {
  width: calc((0.8333em * 2.8) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="2.9"] .score-amount::after {
  width: calc((0.8333em * 2.85) + 0.444444444em * 2);
  /* 2.32em; */ }

.final-score .score-fill[data-score="3.1"] .score-amount::after {
  width: calc((0.8333em * 3.1) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.2"] .score-amount::after {
  width: calc((0.8333em * 3.2) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.3"] .score-amount::after {
  width: calc((0.8333em * 3.3) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.4"] .score-amount::after {
  width: calc((0.8333em * 3.4) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.5"] .score-amount::after {
  width: calc((0.8333em * 3.5) + 0.444444444em * 3);
  /* 4.0226923em; */ }

.final-score .score-fill[data-score="3.6"] .score-amount::after {
  width: calc((0.8333em * 3.6) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.7"] .score-amount::after {
  width: calc((0.8333em * 3.7) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.8"] .score-amount::after {
  width: calc((0.8333em * 3.8) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="3.9"] .score-amount::after {
  width: calc((0.8333em * 3.85) + 0.444444444em * 3);
  /* 3.37884615em; */ }

.final-score .score-fill[data-score="4.1"] .score-amount::after {
  width: calc((0.8333em * 4.1) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.2"] .score-amount::after {
  width: calc((0.8333em * 4.2) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.3"] .score-amount::after {
  width: calc((0.8333em * 4.3) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.4"] .score-amount::after {
  width: calc((0.8333em * 4.4) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.5"] .score-amount::after {
  width: calc((0.8333em * 4.5) + 0.444444444em * 4);
  /* 5.26134614em; */ }

.final-score .score-fill[data-score="4.6"] .score-amount::after {
  width: calc((0.8333em * 4.6) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.7"] .score-amount::after {
  width: calc((0.8333em * 4.7) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.8"] .score-amount::after {
  width: calc((0.8333em * 4.8) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.final-score .score-fill[data-score="4.9"] .score-amount::after {
  width: calc((0.8333em * 4.85) + 0.444444444em * 4);
  /* 4.57115384em; */ }

.table-cell {
  display: table-cell !important;
  width: 50%;
  vertical-align: middle; }

.table {
  display: table !important; }

.table-row {
  display: table-row !important; }

.score-card-total .score-card-total-title {
  color: #999;
  margin-top: 0;
  padding-top: 0;
  font: 300 20px "Google-Oswald", "Arimo", Arial !important; }

.score-card-total .score-card-cell:first-child {
  width: 37%; }

.score-card-total .score-card-cell:last-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px 0 0px; }

.score-card-total .final-num {
  font: 100 85px/1 "Google-Oswald", "Arimo", Arial;
  letter-spacing: -5.5px;
  color: #E23E57;
  margin-bottom: 19px; }

.score-card-total {
  background: #f8f8f8; }

.score-card-total .score-card-phone {
  color: #777 !important; }

.score-card-total .score-card-title {
  color: #aaa; }

.score-total-section,
.score-card-cell table td {
  color: #777; }

.score-card-total .score-card-cell:last-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1); }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .score-amount::after,
  .score-amount::before {
    letter-spacing: 0.2444444em !important;
    line-height: 1.27777778em; } }

.info-notice {
  padding: 10px 30px 10px 50px;
  margin: 0;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-style: italic; }

.info-notice .head-icon {
  position: absolute;
  top: 10px;
  left: 0;
  color: #dedede;
  font-size: 42px; }

#user-comments h2 {
  font-size: 17px; }

.article-info-meta .meta-link-score {
  float: none;
  width: 9em; }

.article-info-meta .meta-link-score .score {
  font: 400 14px Google-Oswald,Arial,sans-serif;
  line-height: 44px;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); }

.article-info-meta .meta-link-score .score-stars {
  position: absolute;
  left: 0.7em;
  top: unset;
  line-height: unset;
  /* text-shadow: none; */
  overflow: hidden;
  white-space: nowrap;
  /* IE/Edge fix */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  -ms-transform: translateY(0.45em);
  /* IE11 fix */ }

.article-info-meta .meta-link-score .score-stars.background {
  color: rgba(220, 220, 220, 0.5);
  text-shadow: none; }

.article-info-meta .meta-link-score .score-stars::after {
  content: "\e91b\e91b\e91b\e91b\e91b"; }

.notice {
  margin-bottom: 15px;
  margin-top: 15px;
  font: 16px/1.5 Arimo; }

#review-body .notice p {
  margin: 0;
  padding: 10px 50px 10px 20px;
  font-style: italic; }

.notice-info {
  border-left: solid 10px #addff1;
  background: url("../i/icon-info.png") no-repeat 98.3% center;
  background-size: 32px; }

.notice-warning {
  background: url("../i/icon-warning.png") no-repeat 98.3% center;
  background-size: 32px;
  border-left: solid 10px #f1e5ad; }

.twitter-tweet-rendered {
  max-width: unset !important; }

.twitter-tweet-rendered iframe {
  max-width: 550px; }

.instagram-media, .instagram-media-rendered {
  margin: 0 auto 10px auto !important; }

table.pricing.inline.widget {
  border: unset;
  border-left: 10px solid #17819f;
  border-spacing: 0; }

table.pricing.inline.widget caption {
  position: relative; }

table.pricing.inline.widget caption h3 {
  padding: 0 0 5px 20px !important;
  text-align: left;
  border-left: 10px solid #17819f; }

table.pricing.inline.widget td {
  border: none;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  background-color: #fafafa;
  color: #555;
  font: 14px/16px Arimo;
  width: 33%;
  height: 45px;
  vertical-align: middle;
  text-align: center;
  position: relative;
  padding: 0; }

table.pricing.inline.widget tr:first-child td {
  border-top: none; }

table.pricing.inline.widget td:first-child {
  width: 20%;
  text-align: left;
  padding-left: 30px; }

table.pricing.inline.widget tbody td:last-child {
  border-right: 10px solid #fafafa; }

table.pricing.inline.widget td a {
  font: 14px/21px Google-Oswald,sans-serif;
  white-space: nowrap;
  color: #d50000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  line-height: 45px;
  z-index: 1;
  text-decoration: none !important; }

table.pricing.inline.widget tbody a:after {
  content: "";
  display: inline-block;
  width: 52%; }

table.pricing.inline.widget img {
  position: absolute;
  top: 50%;
  left: 52%;
  height: 25px;
  transform: translateY(-50%); }

table.pricing.inline.widget tfoot td:first-child {
  padding: 15px 10px 0 0;
  border-left: none;
  background-color: unset;
  text-align: right;
  border: none; }

table.pricing.inline.widget .show-more {
  color: white;
  background-color: #d50000;
  text-decoration: none !important;
  font: 14px Google-Oswald, sans-serif;
  text-transform: uppercase;
  position: static;
  position: unset;
  padding: 11px; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  table.pricing.inline.widget img {
    transform: translateY(40%); } }

table.pricing.inline.widget {
  border-left: none; }
  table.pricing.inline.widget.guide .pricing-info {
    right: 10px;
    top: -30px; }
  table.pricing.inline.widget.guide h3 {
    border-left: none; }
  table.pricing.inline.widget.guide td {
    background: none;
    border-right: none; }
  table.pricing.inline.widget.guide tbody td:first-child:before {
    content: unset; }
  table.pricing.inline.widget.guide tfoot td:first-child {
    padding-top: 5px; }
  table.pricing.inline.widget.guide .show-more {
    position: unset;
    padding: 5px 9px;
    color: #d50000;
    background: #f9f9f9;
    text-transform: uppercase;
    border: 1px solid #d1d1d1;
    border-right: 1px solid #b7b7b7;
    cursor: pointer; }
    table.pricing.inline.widget.guide .show-more:hover {
      background: #d50000;
      color: white; }
  table.pricing.inline.widget tbody td:first-child:before {
    content: "";
    border-left: 10px solid #17819f;
    position: absolute;
    top: -1px;
    left: 0px;
    height: calc(100% + 1px); }

#review-body .news-deal {
  display: flex;
  align-items: center;
  padding: 15px 30px;
  margin-bottom: 15px; }
  #review-body .news-deal img {
    margin: 10px 30px 10px 0;
    filter: brightness(0.98);
    max-width: 75px;
    height: auto; }
  #review-body .news-deal .d-flex {
    flex: 1;
    margin-right: 10px; }
    #review-body .news-deal .d-flex .flex-1.ml-20 {
      margin-left: 0; }
    #review-body .news-deal .d-flex span {
      color: #666;
      display: inline-block;
      margin-bottom: 4px; }
  #review-body .news-deal h3 {
    margin: 10px 0 7px 0;
    padding: 0; }
    #review-body .news-deal h3 a {
      text-decoration: none;
      font-weight: 700;
      color: #666; }
      #review-body .news-deal h3 a:hover {
        color: var(--gsmarena-red, #d50000); }
  #review-body .news-deal .red-button {
    padding: 10px 40px;
    text-transform: unset; }

.st-text.columns p {
  tab-size: 54;
  white-space: pre-wrap;
  margin-left: 50px; }

.st-text.columns a {
  display: inline-block;
  text-decoration: none; }
  .st-text.columns a:hover {
    text-decoration: underline; }

.st-text.columns h3 {
  border-left: 10px solid var(--color-at-a-glance, #17819f);
  padding: 5px 0 5px 10px;
  margin-top: 25px; }

.st-text ul li a[href^="glossary"] {
  font-weight: bold;
  text-decoration: none; }
  .st-text ul li a[href^="glossary"]:hover {
    text-decoration: underline; }

.specs-box {
  display: flex;
  margin: 20px 20px;
  align-items: center;
  color: var(--color-link-active-text, #000); }
  .specs-box > img {
    max-height: 57px; }
  .specs-box .name {
    width: var(--width, 25%);
    margin: 0 20px;
    color: var(--color-tab-text, #616161);
    font: 700 18px "Arimo", Arial, sans-serif;
    text-decoration: none !important; }
    .specs-box .name:hover {
      color: var(--color-section-link-text, #d50000); }
  .specs-box em {
    color: var(--color-button-dark-text, white);
    background-color: var(--color-button-dark-background, #757575); }
  .specs-box .info {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 5px 10px;
    background-color: var(--color-button-background, #eee);
    font: 400 16px "Google-Oswald", Arial, sans-serif;
    line-height: 1.8em; }
    .specs-box .info > em {
      font-size: 26px;
      margin: 0 20px 0 15px;
      padding: 5px 10px; }
  .specs-box .rows,
  .specs-box .columns {
    display: flex;
    flex: 1;
    align-items: center;
    min-height: 3.7em;
    justify-content: center; }
    .specs-box .rows em,
    .specs-box .columns em {
      padding: 0 3px; }
    .specs-box .rows.align-start,
    .specs-box .columns.align-start {
      align-items: start; }
  .specs-box .rows {
    flex-direction: column; }
  .specs-box .columns > div {
    flex: 1; }

.grid_12 .specs-box {
  margin: 0;
  margin-bottom: 10px;
  position: relative;
  margin-top: 46px; }
  .grid_12 .specs-box em {
    font-style: normal; }
  .grid_12 .specs-box > img {
    max-height: 76px;
    margin-right: 10px; }
  .grid_12 .specs-box .name {
    position: absolute;
    top: 0;
    transform: translateY(calc(-100% - 10px));
    width: unset;
    font-size: 16px;
    margin: 0; }
    .grid_12 .specs-box .name br {
      display: none; }
    .grid_12 .specs-box .name .mAh:before {
      content: ", "; }
  .grid_12 .specs-box .info {
    font-size: 15px; }
    .grid_12 .specs-box .info > em {
      margin: 5px 10px 5px 0;
      padding: 3px 4px;
      font-size: 25px; }
    .grid_12 .specs-box .info div {
      margin-left: 5px; }
      .grid_12 .specs-box .info div:first-child {
        margin-left: 0; }
